
@media (min-width:768px) and (max-width:991px)
{
    .top-circle-geom
    {
      top: -100px;
      left: -100px;  
    }
    .top-geom
    {
        top: -100px;
        right: -100px;
    }
    .bottom-circle-geom
    {
        bottom: -100px;
        right: -100px;
    }
    .bottom-geom
    {
        bottom: -100px;
        left: -100px;
    }
    .top-circle-geom,
    .top-geom,
    .bottom-circle-geom,
    .bottom-geom
    {
        width: 250px;
    }
    .separator
    {
        left: -5px;
    }
}

@media (min-width:1200px) and (max-width:1400px)
{
	.mid-container.public-profile-container
	{
		padding: 26rem 15px;
	}
}

@media (min-width:1024px) and (max-width:1199px)
{
    .reflection-btns .btn
    {
        padding: 10px 30px;
    }
    .progress-wrapper
    {
        width: 411px;
        margin: 0 auto;
    }
    .circle-2:after {
        width: 145px;
    }
    .update-content
    {
        min-height: 300px;
    }
    .mid-container.public-profile-container
	{
		padding: 26rem 15px;
	}
    
}

@media (min-width:768px) and (max-width:1023px) and (orientation:landscape) 
{
    .assesment-content-wrap
    {
        padding: 2rem !important;
    }
    .previous-session-container 
    {
        padding: 10rem 15px 10rem !important;
        height: unset;
    }
}
@media (min-width:768px) and (max-width:1023px)
{
    .journal-right-content
    {
        padding: 0 15px !important;
    }
    .assesment-content-wrap
    {
        padding: 1rem;
        min-height: 440px;
        background-size: 100% 475px;
    }

    .welcome-img
    {
        width: 620px;
    }
    .update-content
    {
        min-height: 88%;
        display: block;
        flex-wrap: unset;
    }
    .progress-wrapper
    {
        width: 482px;
        margin: 0 auto;
    }
    .waves-top,
    .waves-bottom
    {
        height: 100px;
        background-size: 100% 100px;
    }
    .geomatric-imgs img
    {
        width: 300px;
    }
    .logo
    {
        text-align: center;
        top: 30px;
    }
    .logo img
    {
        width: 275px;
    }
    body
    {
        font-size: 16px;
    }
    .circle-2:after
    {
        width: 215px;
    }
    .reflection-btns
    {
        text-align: center;
    }
    .reflection-btns .btn
    {
        font-size: 12px;
        padding: 10px;
        margin: 0;
    }
    .journal-tabs
    {
        padding: 10px;
    }
    .journal-tabs a
    {
        padding: 15px;
    }
    .journal-tab-content
    {
        min-height: 525px;
    }
    .signup-form-wrapper
    {
        padding: 2rem;
    }
    .public-profile-container .setting-right-panel
    {
        height: 100%;
    }
    .membership-img img
    {
        height: 200px;
    }
    .invited-points li
    {
        display: flex;
    }
    .invited-points li img
    {
        align-self: flex-start;
        position: relative;
        top: 5px;
    }
    .mid-container.journal-container, .mid-container.journal-screen-container
    {
        padding: 27rem 15px 28rem !important;
    }
}

@media only screen and (min-width:375px) and (max-width:823px) and (orientation:landscape)
{
    .mid-container.checkout-container
    {
        padding: 25rem 15px  22rem!important;
    }
    .mid-container.library-container
    {
        padding: 8rem 15px 6rem!important;
        height: auto;
    }
    .mid-container.ij-container 
    {
        padding: 6rem 15px 7rem !important;
        height:auto;
    }
    .mid-container.home-container
    {
        padding:25rem 15px 25rem !important
    }
    .previous-session-container 
    {
        padding: 10rem 15px 10rem !important;
        height: unset;
    }
    .mid-container
    {
        padding: 18rem 15px !important;
    }
    .mid-container.journey-within-container {
        height: auto;
        padding:5rem 15px !important;
    }
    .signup-container, 
    .mid-container.ms-container,
    .setting-container, .welcome-container,
    .public-profile-container
    {
        padding: 24rem 15px !important;
    }
    .progress-container
    {
        padding: 22rem 15px !important;
    }
    .updates-container
    {
        padding: 32rem 15px !important;
    }
    .mid-container.edit-profile-container {
        padding: 21rem 15px 22rem !important;
    }
    .journey-container
    {
        height: auto;
    }
    .video-listing-wrap
    {
        height:auto;
    }
    .community-container
    {
        padding: 24rem 15px !important;
    }    
}

@media (max-width:767px) and (orientation:landscape)
{
    .mid-container.home-container
    {
        padding:18rem 15px 15rem!important
    }
    mid-container.so-container
    {
        padding: 28rem 15px 26rem !important;
    }
    .mid-container 
    {
        z-index: 0;
        padding: 25rem 15px !important;
    }
    .checkout-container
    {
        padding: 23rem 15px 18rem !important;
    }
    .feeling-container
    {
        padding: 12rem 15px !important;
    }
    .mid-container.ij-container 
    {
        padding: 6rem 15px 7rem !important;
        height:auto;
    }
    .j-video-container
    {
        padding: 22rem 15px !important;
    }
    .mid-container.journey-within-container2 {
        padding: 15rem 15px 16rem !important;
    }
    .main-wrapper.community-wrapper
    {
        height: unset;
    }
    .mid-container.edit-profile-container {
        padding: 27rem 15px 28rem !important;
    }
}

@media (max-width:767px)
{
    a.footer_policy {
        bottom: 5px;
        left: 10px;
        font-size: 13px;
    }   
    .signup-option-content .mt-4
    {
        margin-top:0 !important;
    }
    .mid-container.so-container
    {
        padding: 26rem 15px 22rem !important;
    }
    .content
    {
        margin-top: 50px;
    }
    .mid-container.so-container .email-icon
    {
        display: inline-block;
    }

    .mid-container.so-container .signup-option-content
    {
        margin-top: 0;
    }

    .community-container
    {
        padding: 8rem 15px 6rem !important;
        height: auto;
    }
    body
    {
        font-size: 14px;
    }   
    .welcome-img
    {
        width: 100%;
    }
    .feeling-text
    {
        font-size: 18px;
        line-height: 26px;
    }
    .faces-wrap a
    {
        margin: 0 5px;
    }
    /*.journey-within-container
    {
        padding: 24rem 15px 27rem !important;
    }*/
    .journey-within-container
    {
        padding: 5rem 15px 5rem !important;
        height: auto;
    }
    .library-container
    {
        padding: 9rem 15px 8rem !important;
        height: auto;
    }
    .faces-wrap img
    {
        width: 45px;
        height: auto !important;
    }
    .feeling-text br
    {
        display: none;
    }
    .signup-container{
        padding:6rem 15px 5rem !important;
    }
    .check-box-wrap label span
    {
        flex: 0 0 85%;
        max-width: 85%; 
    }
    .signup-main-wrapper
    {
        overflow: unset;
        display: contents;
        height: 100%;
    }
    .signup-wrapper{
        align-self: unset;
    }
    .signup-form-wrapper
    {
        background:transparent;
        padding: 1rem 2.5rem; 
    }
    .signup-container
    {
        height: auto;
    }
    .signup-wrapper
    {
        background:#E8B653
    }
    .welcome-img
    {
        width: 290px;
    }
    .waves-top,
    .waves-bottom
    {
        height: 85px;
        background-size: 100% 85px;
    }
    .geomatric-imgs img
    {
        width: 200px;
    }
    .logo
    {
        text-align: center;
        top: 38px;
    }
    .logo img
    {
        width: 200px;
    }
    .signup-wrapper .left-img
    {
        display: none;
    }
    .social-signin-btns 
    {
        margin-top: 5px;
    }
    .reflection-btns
    {
        text-align: center;
    }
    .mid-container .btn-primary
    {
        margin: 10px auto 0;
        padding: 8px 15px;
        font-size: 12px;
        display: block;
        width: 230px; 
    }
    .signup-option-content
    {
        margin-top: 60px;
    }
    .p-circle
    {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        border-width: 17px !important;
        position: relative;
        line-height: 88px;
        font-size: 12px;
    }
    .circle-1
    {
        margin-bottom: 3.5rem;
    }
    .circle-1:before
    {
        transform:rotate(28deg)
    }
    .circle-1:after
    {
        transform:rotate(-28deg)
    }
    .circle-2:after
    {
        width: 75px;
        left: 90px;
    }
    .progress-wrapper
    {
        width: 290px;
        display: block;
        margin: 0 auto;
    }
    .bottom-nav
    {
        padding: 5px;
    }
    .bottom-nav ul li img,
    .top-right-links img
    {
        width: 18px;
        height: 18px;
    }
    .bottom-nav ul li a
    {
        font-size: 11px;
    }
    .wave-after-signup{
        bottom: 40px;
    }
    .top-home-link
    {
        top: 5px;
        left: 10px;
    }
    .top-login-links,
    .top-right-links
    {
        top: 5px;
        right: 10px;
    }
    .top-right-links a
    {
        padding: 0 2px;
    }
    .top-right-links img
    {
        top: -1px;
    }
    .journal-tabs
    {
        flex-direction: unset !important;
        background: none;
        box-shadow: none;
        padding: 0;
    }
    .journal-tabs a
    {
        display: unset;
        flex: 0 0 30%;
        max-width: 30%;
        font-size: 12px;
        text-align: center;
        padding: 10px 0;
        margin: 0 3px;
    }
    .journal-tab-content
    {
        min-height: unset;
        margin-top: 10px;
    }
    .journal-right-content h5,
    .journal-right-content h6
    {
        font-size: 14px;
    }
    .journal-right-content p
    {
        font-size: 12px;
    }
    .tab-title
    {
        font-size: 18px;
    }
    .journey-video-content h5
    {
        font-size: 18px;
    }
    .journey-video-content h4
    {
        font-size: 20px;
    }
    .ij-container
    {
        padding: 5rem 15px 8rem !important;
        height: auto;
    }
    .video-listing-wrap
    {
        height: auto;
    }
    .btn-down button
    {
        width: 35px;
        height: 35px;
    }
    .update-content
    {
        min-height: auto;
        margin-top: 1rem;
    }
    .updates-container
    {
        padding:9rem 15px 8rem !important;
        height: auto;
    }
    .setting-container
    {
        padding: 8rem 15px !important;
        height: auto;
    }
    .settings-left-panel, .setting-right-panel
    {
        padding: 1rem;
    }
    .btn-upgrade
    {
        right: 10px;
    }
    .edit-profile-container
    {
        padding: 28rem 15px 27rem !important;
    }
    .public-profile-container
    {
        padding: 37rem 15px 37rem !important;
    }
    .profile-img-slide .slick-prev
    {
        left: 0;
    }
    .ms-container
    {
        padding: 44rem 15px 44rem !important;
    }
    .membership-img
    {
        text-align: center;
    }
    .membership-img img
    {
        height: 250px;
        display: inline-block;
    }
    .recommend-box
    {
        padding: 1rem !important;
    }
    .journal-container,.journal-screen-container
    {
        padding: 8rem 15px 7rem !important;
        height: auto;   
    }
    .journal-container .content,.journal-screen-container .content
    {
        align-self: flex-start;
    }
    .dash-divider:after,
    .dash-divider:before
    {
        display: none;
    }
    .assesment-content-wrap
    {
        margin:0 auto;
        padding: 3rem 2rem 1rem;
        min-height: 500px;
        background-size: 100% 500px;
    }
    .email-verify-container
    {
        padding: 20rem 15px 21rem !important;
    }
    .email-verification-text h4
    {
        font-size: 20px;
        font-weight: bold;
    }
    .email-verification-text p
    {
        font-size: 14px;
        font-weight: 400;
    }
    .image_load img.gif-large {
        width: 100%;
    }
    .sessions_grid {
        display: block;
        text-align: center;
    }
    img.center-img.img-shadow {
        width: 100%;
    }   
    .journey-answerlist li {
        min-width:275px;
    }
    img.gif-large {
        width: 100%;
        padding: 0px 10px;
    }
    .email-verification-text .btn
    {
        width: 275px;
        display: unset;
    }
    .email-verify-container
    {
        padding: 22rem 15px 22rem !important;
    }
    .previous-session-container 
    {
        padding: 8rem 15px 8rem !important;
        height: unset;
    }
    .journal-right-content
    {
        padding:0 15px;
    }
    .helps-container
    {
        padding:9rem 15px 7rem !important;
        height:auto;
    }
    .question-container
    {
        padding:8rem 15px 6rem !important;
        height:auto;
    }
    .image_load img.gif-large
    {
        width:100%;
    }
    .mid-container
    {
        z-index: 0;
        padding: 20rem 15px;
    }
    .divLoader
    {
        height:240px !important;
    }
    .faces-wrap button
    {
        padding: 0 3px;
    }
    .mid-container .btn-upgrade
    {
        position: absolute;
        right: 8px;
        width: auto !important;
        padding: 10px 15px !important;
    }
    .mid-container.checkout-container
    {
        padding: 29rem 15px 27rem !important;
    }

    .l2-que-wrapper .l2-question a
    {
        width: 275px;
        margin-top: 10px;
    }
    .l2-que-wrapper
    {
        margin-top: 8rem;
        padding: 1rem 0;
    }
    .l2-question
    {
        margin: 20px 0;
    }
    .membership-scroll-wrap .media
    {
        flex-wrap: wrap;
        justify-content: center;
    }
    .membership-scroll-wrap .media-body
    {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .membership-scroll-wrap
    {
        margin-top: 10rem;
        height: calc(100% - 165px);
    }
    .membership-scroll-wrap .media img
    {
        width: 100px;
    }
    .l2-progress-wrap .prog-phase:after
    {
        top: 50px;
        right: 98px;
    }
    .l2-progress-wrap .prog-day:after
    {
        top: 50px;
        left: 98px;
    }
    .l2-progress-wrap
    {
        top: 3rem;
        width: 290px;
        margin:0 auto
    }
    .l2-progress-wrap span
    {
        width: 100px;
        height: 100px;
        border: 15px solid;
        font-size: 12px;
        line-height: 75px;
    }
    .l2-progress-wrap .prog-phase
    {
        margin: 65px auto 0;
    }
    .l2-progress-wrap .prog-percent:after
    {
        top: -36px;
    }
    .tabs-scroll-wrap
    {
        height: 64%;
        margin-top: 10rem;
    }
    .level2-tabs-content h5
    {
        font-size: 18px;
    }
    .separator
    {
        left: -5px;
    }
    .l2-veil-content-wrap
    {
        margin-top: 8rem;
    }
    .l2-veil-content-wrap .veil-img
    {
        width: 100px;
    }

    .top-circle-geom
    {
      top: -100px;
      left: -100px;  
    }
    .top-geom
    {
        top: -100px;
        right: -100px;
    }
    .bottom-circle-geom
    {
        bottom: -100px;
        right: -100px;
    }
    .bottom-geom
    {
        bottom: -100px;
        left: -100px;
    }
   .top-circle-geom,
   .top-geom,
   .bottom-circle-geom,
   .bottom-geom
   {
       width: 200px;
   }
   .img-veil,
   .img-journey
   {
       width: 100px;
   }
   .secondlogo
   {
    width: 100px;
   }
   .level2-tabs-wrapper .nav-link
   {
       font-size: 14px;
       padding: 5px;
       border: 2px solid #BE8849;
       margin: 0 2px;
   }
   .level2-tabs-wrapper
   {
       padding: 0;
   }
   .tabs-top-btns li a
   {
        font-size: 12px;
        padding: 8px 10px;
        text-align: center;
   }
   .tabs-top-btns li
   {
       margin-bottom: 10px;
   }    
}

@media (max-width:926px) and (orientation:landscape)
{
    .level-2-wrapper
    {
        height: auto;
        padding: 3rem 0;
    }
    .top-circle-geom
    {
      top: -100px;
      left: -100px;  
    }
    .top-geom
    {
        top: -100px;
        right: -100px;
    }
    .bottom-circle-geom
    {
        bottom: -100px;
        right: -100px;
    }
    .bottom-geom
    {
        bottom: -100px;
        left: -100px;
    }
    .top-circle-geom,
    .top-geom,
    .bottom-circle-geom,
    .bottom-geom
    {
        width: 250px;
    }
    body,
    .btn
    {
        font-size: 14px;
    }
    .secondlogo
    {
        position: unset;
        display: block;
    }
    div.l2-veil-content-wrap
    {
        margin-top: 2rem;
    }
    div.tabs-scroll-wrap
    {
        margin-top: 1rem;
    }
    .separator
    {
        left: -5px;
    }
    .l2-progress-wrap
    {
        top: 2rem;
    }
    div.membership-scroll-wrap
    {
        margin-top: 1rem;
    }
}