@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

html{
    height: 100%;
}
body
{
    color: #000;
    font-size: 18px;
    font-family: Roboto;
    position: relative;
    line-height: 1.6;
    min-height: 100%;
    
    background: #fff;
}
p,figure,h1,h2,h3,h4,h5,h6,ul
{
    margin: 0;
}
.btn,button.btn,a.btn
{
    border-radius: 80px;
    padding: 12px 40px 10px !important;
    border: none !important;
    box-shadow: none!important;
    text-transform: uppercase;
    font-weight: 500;
}
.green_colored
{
    background-color: #016e01 !important;
    width: 150px;
}
.green_colored:hover
{
    background-color: #016e01 !important;
}
.btn-primary,button.btn-primary,a.btn
{
    background:#E8B653;
    color: #fff;
}
.btn-primary.dark-brown
{
    background:#DF8E01;
    color: #fff;
}
.btn-primary:hover
{
    background:#C18642 !important;
    color: #fff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active
{
    background:#C18642 !important;
}
/*.main-wrapper
{
    position: relative;
    overflow:hidden;
}*/
.waves-top
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(./images/waves.png) no-repeat center;
    background-size: 100% 100px;
    /*z-index: 2;*/
}
.waves-bottom
{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: url(./images/waves.png) no-repeat center;
    background-size: 100% 100px;
    transform: rotate(180deg);
}
.logo
{
    position: absolute;
    /*z-index: 9;*/
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 305px;
}
.logo img
{
    width: 270px;
}
.mid-container
{
    position: relative;
    height: 100vh;
    max-width: 1140px;
    display: flex;
    justify-content: center;
    justify-self: auto;
    margin: 0 auto;
    /*z-index: 1;*/
    padding: 0 15px;
}
.content
{
    text-align: center;
    align-self: center;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 80px;
}
.content p
{
    font-weight: 500;
    margin-bottom: 1rem;
}
.welcome-img
{
    width: 600px;
    margin: 0 0 2rem 0;
}
.geomatric-imgs
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
}
.geomatric-imgs img
{
    width: 320px;
    position: absolute;
    opacity: 0.1;
    z-index: -1;
}
.geomatric-imgs .geo-top-left
{
    top: 0;
    left: -100px;
}
.geomatric-imgs .geo-bottom-left
{
    bottom: -2rem;
    left: -130px;
}
.geomatric-imgs .geo-top-right
{
    top: 0;
    right: -100px;
    
}
.geomatric-imgs .geo-bottom-right
{
    bottom: -2rem;
    right: -130px;
}
.img-shadow
{
    box-shadow: 0 10px 25px -5px rgba(0,0,0,0.2);
    border-radius: 20px;
}
.left-img
{
    width: 100%;
}
.social-signin-btns
{
    margin-top: 2rem;
}
.social-signin-btns a
{
    display: inline-block;
    margin-right: 5px;
}
.mid-container .btn-primary
{
    margin: 0 10px;
}
.signup-container
{
    position: relative;
    z-index: 9;
}
.signup-wrapper
{
    box-shadow: 0 10px 25px -5px rgba(0,0,0,0.2);
    background: #fff;
    border-radius: 20px;
    margin-top: 2rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.signup-form-wrapper
{
    background: #E8B653;
    padding: 2rem 3rem;
    height: 100%;
    font-size: 14px;
}
.signup-form-wrapper .form-control
{
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    color: #fff;
    padding: 0;
    font-size: 14px;
    box-shadow: none !important;
}
.signup-form-wrapper .form-group
{
    margin-bottom: 1.5rem;
}
.signup-form-wrapper .form-control::-moz-placeholder
{
    color: #fff;
}
.signup-form-wrapper .form-control::-webkit-input-placeholder
{
    color: #fff;
}
.signup-form-wrapper .form-control::-ms-input-placeholder
{
    color: #fff;
}
.signup-form-wrapper .form-control::-o-input-placeholder
{
    color: #fff;
}
.signup-btn-group
{
    border-bottom: 1px solid #fff;
}
.signup-btn-group .form-control
{
    border-bottom: 0;
}
.signup-btn-group button
{
    background: none;
    border: none;
}
.signup-btn-group button:after
{
    color: #fff;
}
.signup-btn-group .input-group-prepend
{
    position: relative;
    left: -5px;
}
.signup-btn-group .dropdown-menu
{
    border: none;
    border-radius: 0;
    min-width: 110px;
    background: #C18642;
    padding: 0;
}
.signup-btn-group .dropdown-menu a
{
    color: #fff;
    display: block;
    padding: 7px 0px 7px 10px;
}
.signup-btn-group .dropdown-menu a:hover
{
    background: none;
}
.signup-btn-group .dropdown-menu a:last-child
{
    border: none;
}
  
.check-box-wrap 
{
    display: block;
    margin-bottom: 15px;
}
.check-box-wrap input 
{
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
  
.check-box-wrap label 
{
    position: relative;
    cursor: pointer;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
}
.check-box-wrap label span
{
    flex: 0 0 90%;
    max-width: 90%;
    padding-left: 10px;
}  
.check-box-wrap label:before 
{
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #fff;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
    padding: 10px;
    height: 20px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    /* line-break: normal; */
    top: 5px;
}
  
.check-box-wrap input:checked + label:after 
{
    content: '';
    display: block;
    position: absolute;
    top: 9px;
    left: 10px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.btn-arrow
{
    border: none;
    background: #fff;
    border-radius: 100%;
    width: 55px;
    height: 55px;
    color: #E8B653;
    font-size: 28px;
    box-shadow: 0 10px 25px -10px rgb(0 0 0 / 20%);
    position: relative;
}
.btn-arrow i
{
	position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
}
.btn-arrow svg 
{
	position: absolute;
	    top: 50%;
	    left: 0;
	    right: 0;
	    margin: 0 auto;
	    transform: translateY(-50%);
}
.geomatric-imgs .circle-top-left
{
    opacity: 1;
    z-index: 0;
    top: 90px;
    left: -40px;
}
.geomatric-imgs .circle-bottom-right
{
    opacity: 1;
    z-index: 1;
    top: unset;
    bottom: 90px;
    right: -60px;
}
.geomatric-imgs .circle-bottom-left
{
    opacity: 1;
    z-index: 0;
    top: unset;
    bottom: 20px;
    left: -110px;
}
.geomatric-imgs .circle-top-right
{
    opacity: 1;
    z-index: -1;
    top: 20px;
    left: unset;
    right: -110px;
    transform: rotate(180deg);
}
.signup-main-wrapper
{
    position: relative;
    overflow: hidden;
}
.progress-wrapper
{
    overflow:hidden;
}
.p-circle
{
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 22px solid #E8B653;
    position: relative;
    line-height: 110px;
    font-size: 14px;
}
.progress-container .circle-1
{
    margin: 0 auto 5rem;
    color: #E8B653;
}
.circle-1:before
{
    content: '';
    position: absolute;
    width: 1px;
    height: 185px;
    background: #E8B653;
    left: -50px;
    transform: rotate(32deg);
    top: 40px;
    z-index: -1;
}
.circle-1:after
{
    content: '';
    z-index: -1;
    position: absolute;
    width: 1px;
    height: 185px;
    background: #F8986C;
    right: -50px;
    transform: rotate(-32deg);
    top: 40px;
}
.circle-1 span
{
    line-height: 22px;
    position: relative;
    top: 25px;
}
.circle-2
{
    float: left;
    border-color: #76CEE9;
    color: #76CEE9;
    position: relative;
}
.circle-2:after
{
    content: '';
    z-index: -1;
    position: absolute;
    width: 185px;
    height: 1px;
    background: #76CEE9;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 110px;
}
.circle-3
{
    float: right;
    border-color: #F8986C;
    color: #F8986C;
}
.bottom-nav
{
    background: #E8B653;
    padding: 5px 10px;
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    /*z-index: 1;*/
}
.bottom-nav ul
{
    background: #fff;
    border-radius: 80px;
    padding:5px 10px;
    box-shadow: 0 10px 25px -10px rgba(0,0,0,0.2);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;
}
.bottom-nav ul li
{
    flex: 0 0 20%;
    max-width: 20%;
}
.bottom-nav ul li a
{
    color: #666;
    font-size: 12px;
    display: block;
    text-align: center;
    text-decoration: none !important;
    outline: none !important;
}
.bottom-nav ul li img
{
    max-width: 25px;
    max-height: 25px;
    width: 100%;
    height: 100%;
}
.bottom-nav ul li.active a
{
    color: #be8849;
}
.wave-after-signup
{
    bottom: 50px;
}
.top-home-link
{
    position: absolute;
    /*z-index: 9;*/
    top: 1rem;
    left: 1rem;
}
.top-home-link a,
.top-right-links a
{
    color: #fff;
}
.top-right-links a
{
    display: inline-block;
    padding: 0 5px;
    font-size: 14px;
}
.top-right-links.without_login a
{
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    padding: 0px;
    text-decoration: none !important;
}
.top-right-links
{
    position: absolute;
    top: 1rem;
    /*z-index: 9;*/
    right: 1rem;
}
.top-right-links img
{
    width: 20px;
    position: relative;
    top: -2px;
}
.journal-content
{
    text-align: left;
    min-height: 275px;
    border: 1px solid #eee;
    padding: 1.2rem;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 10px 25px -10px rgba(0,0,0,0.2);
}
.journal-content p
{   
    font-size: 14px;
}
.journal-content h5
{
    font-size: 16px;
    margin-bottom: 5px;
}
.journal-tabs
{
    border: none;
}
.journal-tabs a
{
    font-size: 16px;
    color: #000;
    display: block;
    text-align: left;
    background:#fff;
    padding: 7px 25px;
    border: 1px solid #be8849 !important; 
    border-radius: 80px !important;
    margin: 0 5px;
}
.journal-tabs a.active
{
    background: #be8849 !important;
    border: none;
    color: #fff !important;
    border-radius: 80px;
    box-shadow: 0 10px 25px -10px rgba(0,0,0,0.2);
}
.reflection-btns .btn
{
    margin: 0 5px;
    font-size: 12px;
}
.journal-tab-content
{
    text-align: left;
    min-height: 400px;
}
.journal-right-content h5
{
    font-size: 18px;
}
.journal-right-content p
{
    font-size: 12px;
    margin-top: 5px;
    line-height: 17px !important;
    word-break: break-all;
}
.journal-container
{
    position: relative;
    top: -1rem;
}
.journal-screen-container
{
    max-width:1390px !important;
}
.updates-tab-wrap .nav-tabs
{
    padding: 0;
    box-shadow: none;
    border: none;
    height: unset;
}
.updates-tab-wrap .nav-tabs li a
{
    padding: 10px 25px;
}
.update-content
{
    border-radius: 10px;
    background: #fff;
    padding: 1rem;
    box-shadow: 0 10px 25px -10px rgba(0,0,0,0.2);
    text-align: left;
    margin-top: 2rem;
    min-height: 260px;
    display: flex;
    flex-flow: wrap;
}

.update-content h5
{
    font-size: 18px;
}
.update-content p
{
    font-size: 14px;
    margin: 10px 0 10px 0;
}
.update-content small
{
    color: #999;
    font-size: 12px;
    position: absolute;
    bottom: 10px;
}
.update-content-insights
{
    border-radius: 10px;
    background: #fff;
    padding: 1rem;
    box-shadow: 0 10px 25px -10px rgba(0,0,0,0.2);
    text-align: left;
    margin-top: 2rem;
    min-height: 285px;
    display: flex;
    flex-flow: wrap;
}

.update-content-insights h5
{
    font-size: 18px;
    margin-bottom: 0px;
}
.update-content-insights p
{
    font-size: 14px;
    margin: 10px 0 10px 0;
}
.update-content-insights small
{
    color: #999;
    font-size: 12px;
    position: absolute;
    bottom: 10px;
}
.custom-pagination ul li a
{
    border-radius: 100% !important;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin: 0 5px;
    padding: 0;
    font-size: 14px;
    background: #E8B653 !important;
    border: none;
    color:#ffffff !important;
    margin-bottom:10px;
}
.custom-pagination ul
{
    flex-wrap: wrap;
}
.custom-pagination ul li.active a
{
    background: #be8849 !important;
    font-size: 14px !important;
}
.page-item.active .page-link:focus,.page-item .page-link:focus{
    border:none;
    outline:none;
    box-shadow: none;
}
.custom-pagination ul li a span
{
    line-height: 44px;
}
.custom-pagination ul li:first-child a,
.custom-pagination ul li:last-child a
{
    font-size: 28px;
}
.settings-left-panel,
.setting-right-panel
{
    padding: 1.5rem;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0 10px 25px -10px rgba(0,0,0,0.2);
    text-align: left;
}
.settings-left-panel img
{
    width: 120px;
    display: inline-block;
    height: 140px;
}
.setting-right-panel h4
{
    font-size: 18px;
}
.settings-left-panel h5
{
    font-size: 18px;
    margin-bottom: 10px;
}
.settings-left-panel li {
    color: #333333;
    font-size: 15px;
    font-weight: 500;
}
.settings-left-panel .btn
{
    margin-top: 1rem;
    display: block;
}
.setting-right-panel p
{
    color: #999;
    margin-top: 10px;
}
.btn-upgrade
{
    position: absolute;
    right: 0;
    top: 10px;
    width: auto !important;
}
.faqs-link a
{
    color: #E8B653;
    display: block;
    margin: 2rem 0;
    text-decoration: none;
}
.faqs-link a:hover
{
    text-decoration: none;
    color: #be8849;
}
.policies-link li
{
    display: inline-block;
    padding-right: 2rem;
}
.policies-link li a
{
    font-size: 15px;
    color: #E8B653;
    text-decoration: none;
    font-weight: 600;
}
.policies-link li a:hover
{
    text-decoration: none;
}
.public_pro h4 {
    display: inline-block;
}
.profile-img-upload
{
    position: relative;
    overflow: hidden;
    background: #E8B653;
    padding: 7px;
    border-radius: 80px;
    margin-top: 20px;
}
.profile-img-upload input
{
    background: none;
    box-shadow: none !important;
}
.profile-img-upload label
{
    border: none;
    background: none;
    color: #fff;
    text-align: center;
    box-shadow: none !important;
}
.profile-img-upload label:after
{
    display: none;
}
.profile-form .form-group
{
    margin-bottom: 1.2rem;
}
.profile-form label
{
    margin-bottom: 0;
}
.profile-form .form-control
{
    border-radius: 0;
    border: none;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    box-shadow: none !important;
}
.phone-input-group
{
    border-bottom: 1px solid #ddd;
}
.phone-input-group .form-control
{
    border: none;
}
.phone-input-group .btn
{
    padding: 0 20px 0 0;
    
}

.custom-swtich {
	position: relative;
	display: block;
	vertical-align: top;
	width: 90px !important;
    height: 40px;
	padding: 3px;
	margin: 15px 0 0 0;
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
	background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
	border-radius: 80px;
	box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	box-sizing:content-box;
}
.custom-switch-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	box-sizing:content-box;
}
.custom-switch-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 10px;
	text-transform: uppercase;
	background: #eceeef;
	border-radius: inherit;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
	box-sizing:content-box;
}
.custom-switch-label:before, .custom-switch-label:after {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
	box-sizing:content-box;
}
.custom-switch-label:before {
	content: attr(data-off);
	right: 6px;
	color: #aaaaaa;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.custom-switch-label:after {
	content: attr(data-on);
	left: 6px;
	color: #FFFFFF;
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	opacity: 0;
}
.custom-switch-input:checked ~ .custom-switch-label {
	background: #E8B653;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.custom-switch-input:checked ~ .custom-switch-label:before {
	opacity: 0;
}
.custom-switch-input:checked ~ .custom-switch-label:after {
	opacity: 1;
}
.custom-switch-handle {
	position: absolute;
    top: 4px;
    left: 4px;
	width: 37px;
    height: 37px;
    background: linear-gradient(to bottom, #FFFFFF 20%, #f0f0f0);
    background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
    border-radius: 100%;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
}
.custom-switch-handle:before {
	content: "";
    position: absolute;
    top: 39%;
    left: 40%;
    margin: -6px 0 0 -6px;
    width: 20px;
    height: 20px;
    background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
    background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
    border-radius: 100%;
    box-shadow: inset 0 1px rgb(0 0 0 / 2%);
}
.custom-switch-input:checked ~ .custom-switch-handle {
	left: 56px;
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
.custom-switch-label, .custom-switch-handle {
	transition: All 0.3s ease;
	-webkit-transition: All 0.3s ease;
	-moz-transition: All 0.3s ease;
	-o-transition: All 0.3s ease;
}
.profile-img-slide .slick-arrow
{
    font-size: 0;
    border: none;
    background: #E8B653;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
.profile-img-slide .slick-prev
{
    left: -1rem;
}
.profile-img-slide .slick-prev:after
{
    content: '\f104';
    font-size: 24px;
    color: #fff;
}
.profile-img-slide .slick-next:after
{
    content: '\f105';
    font-size: 24px;
    color: #fff;
}
.profile-img-slide .slick-next
{
    right: -1rem;
}
.profile-img-slide figure
{
    text-align: center;
}
.profile-img-slide img
{
    display: inline-block;
    width: 100px;
    height: 90px;
}
.assesment-content-wrap
{
    position: relative;
    background: url(./images/old-paper.png) no-repeat center;
    padding: 1rem 2rem;
    margin-top: 1rem;
    min-height: 370px;
    background-size: 100% 395px;
    
}
.assesment-content-wrap h6
{
    margin-top: 42px;
}
.library-container,.updates-container,.previous-session-container
{
    max-width: unset;
}
.video-listing-wrap
{
    text-align: left;
    height: calc(100vh - 285px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 15px;
}
.video-slider .slick-track
{
    padding-bottom: 20px;
}
.video-slider article
{
    padding: 0 10px;
}
.video-slider .slick-arrow
{
    position: absolute;
    background: #E8B653;
    border: none;
    width: 35px;
    height: 35px;
    font-size: 0;
    color: #fff;
    border-radius: 100%;
    top: 60px;
    left: -10px;
    z-index: 1;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
.video-slider .slick-next
{
    left: unset;
    right: -10px;   
}
.video-slider .slick-prev:after
{
    content: '\f104';
    font-size: 24px;
    color: #fff;
}
.video-slider .slick-next:after
{
    content: '\f105';
    font-size: 24px;
    color: #fff;
}
.video-listing-wrap h5
{
    font-size: 18px;
}
.video-listing-wrap h5 span
{
    font-size: 14px;
    color: #999;
}
.video-card
{
    border: none !important;
    border-radius: 10px !important;
    overflow: hidden;
    box-shadow: 0 10px 25px -10px rgba(0, 0, 0, 0.2);
    display: inline-block !important;
}
.video-card small
{
    font-size: 12px;
    color: #999;
}
.video-card h5
{
    font-size: 14px;
}
.video-card p
{
    font-size: 14px;
    font-weight: 400;
}
.video-card .card-body
{
    padding: 10px 15px;
}
.card-links a
{
    color: #E8B653;
    font-size: 14px;
    display: inline-block;
    padding-right: 3px;
}
.video-modal .modal-dialog
{
    max-width: 650px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 25px -10px rgba(0, 0, 0, 0.2);
}
.video-modal .modal-content
{
    border: 0;
}
.video-modal .modal-header
{
    padding: 0;
}
.video-modal .modal-header h5
{
    padding: 1rem;
    background: #E8B653;
    color: #fff;    
    flex: 0 0 100%;
    max-width: 100%;
}
.video-modal .modal-header .close
{
    position: absolute;
    color: #fff;
    right: 15px;
    opacity: 1;
    top: 20px;
}
.video-card-min-height
{
    min-height: 275px;
}
.btn-down
{
    position: fixed;
    right: 20px;
    bottom: 80px;
    z-index: 9;
    animation: MoveUpDown 1s linear infinite;
}
.btn-down button
{   
    color: #fff;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    border: none;
    text-align: center;
    background: #be8849;
}
@keyframes MoveUpDown {
    0%, 100% {
        bottom: 90px;
    }
    50% { 
        bottom: 80px;
    }
}

.divider
{
    color: #fff;
    margin-top: 1rem;
    position: relative;
}
#login_form .divider
{
    margin-top: 4rem;
}
.divider:after,
.divider::before
{
    content: '';
    width: 75px;
    height: 1px;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #fff;
}
.divider::before
{
    left: -200px;
}
.divider::after
{
    right: -200px;
}
.social-login-btns
{
    margin-top: 15px;
}
.social-login-btns a
{
    display: inline-block;
    padding: 5px 15px;
    border-radius: 80px;
    background: #fff;
    color: #000;
    margin: 0 5px;
}
.social-login-btns a:first-child
{
    background: #4C69BA;
    color: #fff;
    
}
.faces-wrap
{
    margin-top: 2rem;
}
.faces-wrap a
{
    display: inline-block;
    margin: 0 10px;
}
.answer-wrap
{
    background: #ffffff;
    border-radius: 10px;
    padding: 1rem;
    font-size: 14px;
    display: block;
    color: #000;
    box-shadow: 0 10px 25px -10px rgba(0,0,0,0.2);
    border: 2px solid #E8B653;
}
.answer-wrap:hover{
    text-decoration: none;
    color:#ffffff;
    background:#e8b653;
}
.answer-wrap p{
    margin-bottom: 0;
}
.invitation-form textarea,.end_of_journey_responses textarea
{
    border-color: #E8B653;
    border-radius: 10px;
}
.membership-img img
{   
    height: 300px;
}
.membership-container
{
    padding: 2rem;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 2rem;
    box-shadow: 0 10px 25px -10px rgba(0, 0, 0, 0.2);
}
.invited-points li 
{
    padding: 5px 0;
}
.invited-points li img
{
    width: 16px;
    position: relative;
    top: -1px;
    margin-right: 5px;
}
.coupon-form .form-control
{
    box-shadow: none !important;
    border: none;
    border-bottom: 1px solid #dddd;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
}
.text-green
{
    color: #E8B653;
}
.recommend-box
{
    background: #F2B53B;
    padding: 2rem;
    margin-top: 20px;
    color: #fff;
    text-align: center;
    border-radius: 20px;
}
.recommend-box span
{
    color: #000;
    font-weight: 500;
    margin-top: 10px;
    display: block;
}
.recommend-box .btn
{
    background: #47C242;
}
.btn-trial
{
    border-radius: 5px;
    padding: 5px 25px;
    background: #fff;
    border: 1px solid #C18642;
    color: #666;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}
.coupon-content p
{
    font-size: 14px;
}
.reflection-content
{
    box-shadow: 0 10px 25px -10px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    background: #fff;
    border-radius: 10px;
    height: 100%;
}
.end-of-journey-tab
{
    position: relative;
    padding: 1.5rem;
    box-shadow: 0 10px 25px -10px rgb(0 0 0 / 20%);
    background: #fff;
    border-radius: 10px;
}
.end-of-journey-tab h6
{
    font-size: 14px;
    line-height: 20px;
}
.divider
{
    position: relative;
}
.dash-divider:after,
.dash-divider:before
{
    content: '';
    width: 1px;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px dashed #ddd;
}

.dash-divider:after
{
    left: unset;
    right: -0px;
}
/*****************************************************************************************/
.onboarding video.play_video {
    width: 100%;
    box-shadow: 0 10px 25px -5px rgb(0 0 0 / 20%);
    border-radius: 20px;
}
img.img-responsive.load_video {
    max-height: 50px;
    max-width: 50px;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
button.custom_facebook.metro {
    font-family: Helvetica,sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #ffffff;
    cursor: pointer;
    font-size: 12px;
    text-decoration: none;
    text-transform: capitalize;
    transition: background-color .3s,border-color .3s;
    background-color: #4c69ba;
    border: 1px solid #4c69ba;
    padding: 10px 20px;
    display: inline-block;
    margin: 0 10px 0 15px;
    border-radius: 50px;
}
button.custom_facebook.metro i {
    margin-right: 5px;
}
button.social_google_login div {
    margin-right: 1px !important;
    padding-right: 6px !important;
    background: #ffffff !important;
}
button.social_google_login {
    box-shadow: none !important;
    border: 1px solid #cccccc !important;
    border-radius: 50px !important;
    padding: 0px 14px !important;
    background: #ffffff !important;
    color: #333333 !important;
    font-size: 13px !important;
}
button.plain_btn {
    background: transparent;
    border: none;
    color: #333333;
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    padding: 0px 5px 0px 5px;
    font-size: 14px;
}
button.plain_btn:hover,button.plain_btn:focus {
    background: transparent;
    border: none;
    color: #ffffff;
}
a.external_link{
    text-decoration: none !important;
    font-weight: 500;
}
#signup_form .errorMsg,#login_form .errorMsg {
    color: #333333;
    font-weight: 500;
}
.react-tel-input .form-control {
    background: transparent !important;
    border: none !important;
    width: 100% !important;
    outline: none;
}
.react-tel-input .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
}
.react-tel-input .selected-flag:hover {
    background-color: transparent !important;
    border: none !important;
}
.react-tel-input .selected-flag .arrow {
    border-top: 4px solid #ffffff !important;
}
.react-tel-input ul.country-list {
    text-align: left;
}
#signup_form .form-control:focus,#login_form .form-control:focus {
    background-color: transparent;
}
#signup_form input,#login_form input
{
    color:#ffffff;
}
/*#loading_image
{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    z-index:99999;
}*/
/*#overlay {
    background: #333333;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 20%;
    opacity: .80;
}*/
.image_load img.gif-large {
    border-radius: 30px;
}

/**************************************************************************************************/
.email-verification-text h4
{
    font-size: 24px;
    font-weight: bold;
}
.email-verification-text p
{
    font-size: 16px;
    font-weight: 400;
}
.cur_pointer
{
    cursor: pointer;
}
.coupon_msg span {
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #E8B653;
    padding: 6px 15px;
    font-style:italic;
}
.coupon_msg {
    margin-bottom: 5px;
}
footer figure{
    margin: 0px;
}
figure.geomatric-imgs {
    margin-bottom: 0px;
}
.invitation-form textarea:focus,.end_of_journey_responses textarea:focus {
    border-color: #E8B653;
    box-shadow: 0 0 0 0.2rem rgb(232 182 83 / 25%);
}
.bottom-nav li.active {
    font-weight: 600;
}
.bottom-nav li.active img {
    filter: invert(64%) sepia(5%) saturate(4559%) hue-rotate(353deg) brightness(87%) contrast(91%);
}
.modal-header {
    background: #e8b653;
    padding: 10px 20px;
}
.modal-header .modal-title {
    font-size: 22px !important;
    color:#ffffff !important;
}
.divLoader
{
    background: #333333;
    width: 100%;
    margin: 0 auto;
    height:270px;
    position: relative;
}

.library_screen .divLoader
{
    background: #333333;
    width: 100%;
    margin: 0 auto;
    height:130px;
    position: relative;
}

img.img-responsive.load_video {
    max-height: 50px;
    max-width: 50px;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

video.play_video {
    width: 100%;
    border:none !important;
}
video.play_video:focus {
    border:none !important;
    outline: none;
}

video::-internal-media-controls-download-button {
    display:none;
}

video::-webkit-media-controls-enclosure {
    overflow:hidden;
}

video::-webkit-media-controls-panel {
    width: calc(100% + 30px); /* Adjust as needed */
}
.pdf_file img.img-responsive {
    width: 25px;
    margin-right: 10px;
}
.audio_file img.img-responsive {
    width: 25px;
}
.pdf_file,.audio_file
{
    float: right;
    margin-right: 10px;
}
.video-slider article {
    padding-left: 0px !important;
}
div#myTab-tabpane-journal h5 {
    margin-bottom: 5px !important;
}
.reflection-content h5 {
    text-align: left;
}
nav.journal-tabs {
    border-bottom: none;
}
svg.svg-inline--fa.fa-phone {
    transform: scaleX(-1);
}
/******************************************************************************/
.toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
  }
  .toggle-switch-checkbox {
    display: none;
  }
  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #ccc;
    border-radius: 20px;
    margin: 0;
  }
  .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .toggle-switch-inner::before, .toggle-switch-inner::after {
    display: block;
    float: left;
    width: 50%;
    height: 34px;
    padding: 0;
    line-height: 34px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }
  .toggle-switch-inner:before {
    content: "Yes";
    text-transform: uppercase;
    padding-left: 10px;
    background-color: #f90;
    color: #fff;
  }
  .toggle-switch-disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .toggle-switch-disabled::before {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .toggle-switch-inner::after {
    content: "No";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
  }
  .toggle-switch-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #ccc;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
  }
  
  .toggle-switch-inner::before {
    content: attr(data-yes);
    /* other styles */
  }
  .toggle-switch-inner::after {
    content: attr(data-no);
    /* other styles */
  }
  
  .toggle-switch.small-switch {
    width: 40px;
  }
  .toggle-switch.small-switch .toggle-switch-inner:after, .toggle-switch.small-switch .toggle-switch-inner:before {
    content: "";
    height: 20px;
    line-height: 20px;
  }
  .toggle-switch.small-switch .toggle-switch-switch {
    width: 16px;
    right: 20px;
    margin: 2px;
  } 
  .profile-img-slide img:focus {
    outline: 2px solid #e8b653;
    padding: 10px;
}
.sessions_grid {   
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: inline-block;
    background: #e8b653;
}
.sessions_grid a {
    margin-bottom: 0px;
    padding: 15px 20px;
    color: #ffffff;
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    font-weight: 500;
}
.sessions_grid a:hover {
    text-decoration: none;
    color: #ffffff;
}
.sessions_grid:hover {   
    background: #C18642;
}
a.see_coupons {
    color: #e8b653;
    font-weight: 500;
    text-decoration: none;
    font-style: italic;
}
a.see_coupons:hover {
    text-decoration: none;
    color: #C18642;
}
.coupon_available {
    border: 4px dashed #e8b653;
    padding: 15px 20px;
    margin-bottom: 20px;
}
.coupon_right {
    text-align: center;
    border: 3px dashed #333333;
    display: inline-block;
    padding: 10px;
}
.coupon_right a {
    color: #e8b653;
    display: block;
}
.coupon_right a:hover {
    text-decoration: none;
    color: #C18642;
}
input.coupon_name {
    display: inline-block;
    border: none;
    font-size: 20px;
    font-weight: 600;
    width: 52%;
}
input.coupon_name:focus {
    border:none;
    outline:none;
}
.soul_copied {
    text-align: center;
    color: #008000;
    font-size: 18px;
    padding: 10px 20px 0px 20px;
}
h4.free_plan_limit {
    color: #ffffff;
    display: inline-block;
    border: 2px solid #ff0000;
    padding: 10px 15px;
    background: #ff0000;
    border-radius: 10px;
    font-size: 16px;
}
.custom_anchor
{
    cursor:pointer;
    display: inline-block;
    color:#be8849;
}
/************************************************************/
.faces-wrap button img {
    height: 70px;
}
.faces-wrap button {
    border: none;
    background: none;
}
img.center-img.img-shadow {
    width: 500px;
}
.journey-answerlist
{
    margin-top: 1rem;
}
.journey-answerlist li {
    padding: 10px;
    display: block;
    color: #000;
    text-align: left;
    border: 2px solid #E8B653;
    border-radius: 10px;
    margin-bottom: 1rem;
    cursor: pointer;
    font-weight: 500;
    min-width:340px;
}
.forgot_password input.form-control {
    border-color: #E8B653;
    border-radius: 10px;
    height: 45px;
}
.forgot_password input.form-control:focus {
    border-color: #E8B653;
    box-shadow: none;
}
/**********************************************************************/
.centered_video video.play_video {
    width: 100%;
    box-shadow: 0 10px 25px -5px rgb(0 0 0 / 20%);
    border-radius: 20px;
}
.journey_end_text {
    box-shadow: 0 10px 25px -5px rgb(0 0 0 / 20%);
    padding: 40px 40px;
    border-radius: 20px;
}
/**************************************************************************/
.premium-video-card .overlay-text
{
    position:absolute;
    left:0;
    top:0;
    z-index:99;
    text-align:center;
    color:#fff;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.7);
    font-size:36px;
    text-decoration:none !important;
    display: block;
}
.premium-video-card .overlay-text p
{
    position:absolute;
    top:50%;
    transform:translateY(-50%);
}
.premium-video-card .overlay-text i
{
    margin-bottom:1rem;
    font-size:24px;
}
.premium-video-card .overlay-text span
{
    font-size:18px
}
.overlay-text
{
    display: none;
}
img.public_profile_active {
    border: 4px solid #e8b653;
    padding: 10px;
    border-radius: 10px;
}
img.existing_public_avatars
{
    cursor: pointer;
}
.img-end-journey
{
    box-shadow: 0 10px 25px -5px rgb(0 0 0 / 20%);
    border-radius: 20px;
}
.image_load
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99999;
    width: 100%;
    background : rgba(0,0,0,0.8);
    height: 100vh;
}
.image_load img.gif-large
{
    width: 400px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    right: 0;
    margin: 0 auto;
}
#overlay
{
    display: none;
}
.top-home-link,
.top-right-links
{
    z-index:1;
}
p.signup-eye,#login_form p.signin-eye
{
    border: none;
    background: none;
    color: #ffffff;
    float: left;
    position: absolute;
    right: 18px;
    top: 12px;
    z-index: 1;
}
.mid-container.public-profile-container
{
    padding: 26rem 15px;
}

.login-form .text-danger,.setting-right-panel .form-group .text-danger,.ij-container .form-group .text-danger
{
	font-size: 14px;
    font-weight: bold;
    position: absolute;
    left: 15px;
}

.login-form .form-group,.setting-right-panel .form-group,.ij-container .form-group
{
	margin-bottom:1.5rem !important;
	position:relative
}
.checkout-container .braintree-form__field-group
{
	position:relative;
	margin-bottom:15px !important;
	padding-bottom:10px;
}
.checkout-container .braintree-form__field-error
{
    display: block;
    position: absolute;
    bottom: -8px;
}

.update-content
{
    position:relative;
}

.update-content h5,.update-content p
{
    flex:0 0 100%;
    max-width:100%;
}
.update-content small
{
    left: 15px;
}
.country 
{
    padding-left: 45px !important;
}
.cookies_error
{
    left: 0;
    right: 0;
}
.library-container
{
    padding:0;
}
.col-12.grey_background {
    background: #EEEEF6;
    padding-top: 25px;
    padding-bottom: 25px;
}
.video-listing-wrap .grey_background h5 span {
    color: #000000;
}
.pp-container
{
    max-width: 100%;
    padding: 0;
}
a.footer_policy {
    position: absolute;
    z-index: 999999;
    text-align: left;
    padding-left: 0;
    color: #ffffff !important;
    font-weight: 700;
    bottom: 10px;
    left: 15px;
}
footer {
    text-align: left;
}


.secondlogo
{
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 150px;
}
.secondlogo img
{
    width: 100%;
}
.level-2-wrapper
{
    position: relative;
    height: 100vh;
    overflow: hidden;
}
.support-link
{
    color: #E8B653 !important;
    display: inline-block;
    margin-top: 1rem;
}
.top-circle-geom,
.top-geom,
.bottom-circle-geom,
.bottom-geom
{
    position: absolute;
    z-index: -1;
}
.top-circle-geom
{
    top: -220px;
    left: -220px;
}
.top-geom
{
    top: -220px;
    right: -220px;
    width: 500px;
}
.bottom-circle-geom
{
    bottom: -220px;
    right: -220px;
}
.bottom-geom
{
    bottom: -220px;
    left: -220px;
    width: 500px;
}
.level2-tabs-wrapper .nav-pills
{
    justify-content: center;
}
.level2-tabs-wrapper .nav-link
{
    color: #BE8849;
    padding: 10px 25px;
    border: 3px solid #BE8849;
    border-radius: 80px;
    margin: 0 5px;
}
.level2-tabs-wrapper .nav-link.active,
.level2-tabs-wrapper .nav-link:hover,
.level2-tabs-wrapper .nav-link:focus
{
    background: #BE8849;
    color: #fff;
}
.tabs-top-btns
{
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
}
.tabs-top-btns li
{
    display: inline-block;
    margin-bottom: 20px;
    padding: 0 5px;
}
.tabs-top-btns li a
{
    padding: 12px 35px;
    background: #C18642;
    border-radius: 80px;
    color: #fff;
    display: inline-block;
}
.tabs-top-btns li:first-child a
{
    background: #E8B653;
}
.tabs-top-btns li:nth-child(2) a
{
    background: #DF8E01;
}
.tabs-top-btns li:nth-child(3) a
{
    background: #F8A273;
}
.level2-tabs-content h3
{
    font-size: 24px;
    margin-top: 10px;
}
.tabs-scroll-wrap
{
    position: relative;
    height: calc(100vh - 260px);
    margin-top: 14rem;
}
.scrollable-element
{   
    overflow-y: auto;
}

.l2-summary-points li
{
    margin-top: 10px;
}
.separator
{
    width: 1px;
    background: #ddd;
    height: 100%;
    position: absolute;
    top: 0;
    left: -55px;
}
.l2-veil-content-wrap
{
    margin-top: 5rem;
}
.l2-progress-wrap
{
    position: relative;
    overflow: hidden;
    text-align: center;
    top: 5rem;
    width: 445px;
    margin: 0 auto;
}
.l2-progress-wrap span
{
    width: 150px;
    height: 150px;
    border: 20px solid transparent;
    text-align: center;
    border-radius: 100%;
    line-height: 115px;
    display: inline-block;
    background: #fff;
}
.l2-progress-wrap span:after
{
    content: '';
    position: absolute;
}
.l2-progress-wrap .prog-day
{
    border-color: #F8986C;
    float: left;
    color: #F8986C;
}
.l2-progress-wrap .prog-day:after
{
    background: #F8986C;
    top: 120px;
    left: 140px;
    width: 1px;
    height: 175px;
    transform: rotate(-31deg);
    z-index: -1;
}
.l2-progress-wrap .prog-percent
{
    border-color: #76CEE9;
    color: #76CEE9;
    float: right;
}
.l2-progress-wrap .prog-percent:after
{
    background: #76CEE9;
    top: -12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 1px;
    height: 175px;
    z-index: -1;
    transform: rotate(90deg);
}
.l2-progress-wrap .prog-phase
{
    bottom: 0;
    border-color: #90B930;
    margin: 100px auto 0;
    color: #90B930;
}
.l2-progress-wrap .prog-phase:after
{
    background: #F8986C;
    top: 120px;
    right: 140px;
    width: 1px;
    height: 175px;
    transform: rotate(31deg);
    z-index: -1;
}
.membership-plan li
{
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
}
.membership-plan li span
{
    float: left;
}
.membership-plan li button
{
    float: right;
}
.membership-scroll-wrap
{
    margin-top:12.5rem;  
    height: calc(100% - 245px); 
}
.membership-plan li button
{
    padding: 5px 10px;
}

.membership-plan p
{
    margin-bottom: 0px !important;
    text-align: left !important;
}

.l2-video-wrapper
{
    position: relative;
    margin-top: 22%;
}
.l2-video-wrapper video
{
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 10px 25px -5px rgba(0,0,0,0.2);
}
.l2-video-wrapper button
{
    background: none;
    border: none;
    position: absolute;
    top: 15px;
    right: 10px;
}
.l2-video-wrapper button i
{
    width: 45px;
    height: 45px;
    background: rgba(0,0,0,0.6);
    border-radius: 100%;
    color: #fff;
    font-size: 24px;
    line-height: 45px;
}
.l2-que-wrapper
{
    border-radius: 20px;
    box-shadow: 0 10px 25px -5px rgba(0,0,0,0.2);
    overflow: hidden;
    background: url(./images/video-bg.jpg) no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 1.5rem 0;
    margin-top: 10rem;
    position: relative;
}
.l2-que-wrapper .l2-question a
{
    color: #fff;
    display: inline-block;
    padding: 10px 25px;
    border-radius: 10px;
    border: 1px solid #fff;
    margin-top: 15px;
    width: 340px;
}
.l2-question
{
    margin: 35px 0;
}
.home-icon
{
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: #fff;
}
.home-icon:hover,
.home-icon:focus
{
    color: #fff;
}
.fullpage-video
{
    width:100%;
    height: 100vh;
    background: #000;
    margin-bottom: -8px;
}
.l2-question-bg
{
    width: 100%;
    height: 100vh;
    background: url(./images/video-bg.jpg) no-repeat center;
    background-size: cover;
}
.l2-question-bg .l2-que-wrapper
{
    background: none;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;
}
.l2-question-bg .l2-question
{
    margin: 8rem 0;
}

[data-braintree-id="toggle"] {
  display: none;
}
.existing_membership p {
    margin-bottom: 0px;
}
.existing_membership {
    padding: 30px;
    border-radius: 20px;
    box-shadow: 20px 20px 50px 15px #ececec;
    margin: 0 auto;
}

h4.membership_title {
    color: #e8b653;
    text-decoration: underline;
    font-size: 22px;
    margin-bottom: 20px;
}

.existing_membership h5 {
    margin-top: 20px;
    font-size: 18px;
}

.braintree_subscriptions
{
    padding: 30px;
    border-radius: 20px;
    box-shadow: 20px 20px 50px 15px #ececec;
    margin: 0 auto;
    text-align: left;
}
.braintree_subscriptions p {
    margin-bottom: 5px;
}
.description_centered div
{
    text-align: center !important;
}

form#submit_members_add_form input {
    border: 2px solid #E8B653;
    border-radius: 25px;
    padding: 30px 20px;
}

.success_message
{
    font-weight:600;
    color:#008000;
}
.error_occured
{
    font-weight:600;
    color:#ff0000;
}